
import { defineComponent } from 'vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import InventoryService from '@/services/InventoryService';
import UiSearch from '@/components/form/UiSearch.vue';
import UiButton from '@/components/form/UiButton.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default defineComponent({
  name: 'ProductUnpublished',
  data: () => ({
    isLoaded: false,
    isProcessing: false,
    drugs: [],
    drugsTotal: 0,
    drugPage: 1,
    drugPages: 0,
    searchTimeoutId: 0,
    searchInput: '',
  }),
  computed: {
    currentItems(): number {
      const currentTotal = 10 * this.drugPage;
      return currentTotal >= this.drugsTotal ? this.drugsTotal : currentTotal;
    },
  },
  components: {
    PreLoader,
    UiSearch,
    UiButton,
    Pagination,
  },
  watch: {
    async searchInput() {
      this.drugPage = 1;

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        await this.searchDrugs();
      }, 300);
    },
  },
  mounted() {
    this.searchDrugs();
  },
  methods: {
    async searchDrugs() {
      try {
        const params = {
          title: this.searchInput,
          published: false,
          order_by: 'title:asc',
          page: this.drugPage,
          take: 10,
        };

        const response = await InventoryService.drugSearch(params);

        this.drugs = response.data;
        this.drugsTotal = response.total;
        this.drugPages = response.last_page;

        this.isProcessing = false;
        this.isLoaded = true;

        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        if (error) this.isProcessing = false;
      }
    },
    editProduct(drug: any): void {
      this.$router.push({
        name: 'ProductEdit',
        params: {
          drugId: drug.slug || drug.id,
        },
      });
    },
  },
});
